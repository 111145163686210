import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Product from "../components/product"

const ProductPage = () => {
  const data = useStaticQuery(query)
  return (
    <>
      <Product
        limited
        title="Demarrage"
        description={description}
        shortDescription="Printed beautifully on Hahnemühle museum etching 350 gsm paper using the highest quality museum grade giclée pigment printing, ensuring lasting intense colors and rich tone depth."
        price={85}
        images={[data.demarrage_1, data.demarrage_2]}
        social={data.social}
        gumroadlink="https://gum.co/FsIUF"
        slug="/demarrage/"
      />
    </>
  )
}

const query = graphql`
  fragment image on File {
    childImageSharp {
      fluid(maxWidth: 1340, quality: 95) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  query {
    social: file(relativePath: { eq: "demarrage_1.jpg" }) {
      childImageSharp {
        fixed(width: 1200, height: 630) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    demarrage_1: file(relativePath: { eq: "demarrage_1.jpg" }) {
      ...image
    }
    demarrage_2: file(relativePath: { eq: "demarrage_2.jpg" }) {
      ...image
    }
  }
`
const description = (
  <>
    <p>
      Printed beautifully on Hahnemühle museum etching 350 gsm paper using the
      highest quality museum grade giclée pigment printing, ensuring lasting
      intense colors and rich tone depth.
    </p>

    <p>Size: 40cm × 50cm</p>

    <p>Numbered edition of 50</p>

    <p>
      All prints are made on heavy quality paper, so all prints are shipped flat
      packaged to ensure delivery of the print in mint condition.
    </p>
  </>
)

export default ProductPage
